
import logo from '../../assets/icon-white.png';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RightOutlined,
  LeftOutlined
} from '@ant-design/icons';
import '../../styles/SidebarMenu.css';
import { FormattedMessage } from 'react-intl';
import SidebarMenu from './SidebarMenu';
import Sider from 'antd/es/layout/Sider';
import { useNavigate } from 'react-router-dom';

function Sidenav() {
  const currentLang = useSelector((state) => state?.user?.currentLang);
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth <= 1000;
      setCollapsedWidth(isSmallScreen ? 0 : 80);
      setCollapsed(true);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const collapseButtonStyle = {
    float: 'right',
    top: '180px',
    position: 'absolute',
    marginLeft: currentLang === 'en' ? (collapsed ? '80px' : '230px') : '0',
    marginRight: currentLang !== 'en' ? (collapsed ? '70px' : '220px') : '0',
  };

  const CollapseButton = ({ collapsed, toggleCollapse }) => {
    return (
      <div className={`buttonCollaps ${collapsed ? 'collapsed' : 'expanded'}`} style={collapseButtonStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="97" viewBox="0 0 32 97" fill="none">
          <path d={currentLang === 'en' ? "M0 0.67334L20.5022 12.3889V85.6111L0 97.3267V0.67334Z" : "M21.3076 96.6484L0.0339044 84.9525L-0.0338896 11.7303L21.2181 -0.00485011L21.3076 96.6484Z"} fill="#0E103A" />
          <foreignObject x={currentLang === 'en' ? "-3" : "-7"} y="0" width="100%" height="100%">
            <button onClick={toggleCollapse} className="collapseButton">
              {currentLang === 'en' ?
                collapsed ? <RightOutlined className="icon" /> : <LeftOutlined className="icon" /> :
                collapsed ? <LeftOutlined className="icon" /> : <RightOutlined className="icon" />
              }
            </button>
          </foreignObject>
        </svg>
      </div>
    );
  };

  return (
    <Sider
      collapsed={collapsed}
      onCollapse={setCollapsed}
      width={230}
      breakpoint="lg"
      collapsedWidth={collapsedWidth}
      className={`sidebar decoration-none rounded-md xl:my-6 ${currentLang === "ar" ? "xl:mr-8" : "xl:ml-9"}`}
    >
      <div className="brand mx-3 my-3 decoration-none">
        <div onClick={() => navigate(`/`)} className='decoration-none text-black decoration-none cursor-pointer'>
          <span
            className={`text-[16px] mt-1 text-center ${!collapsed ? "mx-2" : "mx-5"}`}>
            <img src={logo} alt="" style={{ height: "15px" }} />
          </span>

          {!collapsed && (
            <span className="text-[16px]">
              <FormattedMessage id="IBDL_admin_control" />
            </span>
          )}
        </div>
      </div>
      <hr />

      <SidebarMenu />

      <CollapseButton className={"hidden"} collapsed={collapsed} toggleCollapse={() => setCollapsed(!collapsed)} />
    </Sider>
  );
}

export default Sidenav;
