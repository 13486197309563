import { Button, Menu, Space, Dropdown, Avatar, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import useGetUserData from "../../hooks/useGetUserData";
import React from "react";
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import langIcon from "../../assets/images/lang-change-icon.svg"
import { decryptData } from '../../helpers/apiUtils';
import { setCurrentLang, setUserData } from "../../services/store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
function TopHeader() {
  const navigate = useNavigate();
  const { user } = useGetUserData();
  const dispatch = useDispatch();
  const decryptedItem = decryptData(user.name);
  const currentLang = useSelector((state) => state?.user?.currentLang);
  const handelChangeLng = () => {
    dispatch(setCurrentLang(currentLang === "ar" ? "en" : "ar"));
  }
  return (
    <Flex justify="end" className="my-3 xl:mx-5">
      <Dropdown overlay={
        <Menu>
          <Menu.Item
            className="logout-menu-item"
            key="1"
            icon={<LogoutOutlined />}
            onClick={() => {
              dispatch(setUserData(null));
              localStorage.clear();
              navigate("/login");
              return;
            }}
          >
            <FormattedMessage id={'logout'} />
          </Menu.Item>
        </Menu>
      }>
        <button
          onClick={(e) => e.preventDefault()}
          className="text-black"
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            cursor: 'pointer'
          }}
        >
          <Space>
            <Avatar size={32} src="" icon={<UserOutlined />} />
            <p className="user-name">{decryptedItem}</p>
            <DownOutlined />
          </Space>
        </button>
      </Dropdown>

      <Button className="border-none mt-1" onClick={handelChangeLng} icon={<img src={langIcon} alt="" />}>
        {currentLang === "ar" ? "English" : "العربية"}
      </Button>
    </Flex>
  );
}

export default TopHeader;
