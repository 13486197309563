import React, { FC, useState } from 'react';
import { Breadcrumb, Button, Radio, Col, Input, Popover, Row, Space, Flex } from 'antd';
import { PlusOutlined, UploadOutlined, ClearOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { RadioChangeEvent } from 'antd/lib';

interface FilterTableProps {
  buttonText?: any;
  search_placeholder?: any;
  onClick?: () => void;
  onClickUpload?: () => void;
  tabName?: any;
  items?: any;
  isUpload?: boolean;
  isSearch?: boolean;
  isAdded?: boolean;
  isFilter?: boolean;
  isFilterLang?: boolean;
  isFilterQuestionsBank?: boolean;
  onSearch?: (value: string) => void;
  onRadioChange?: (data: { questionType: string, difficultyLevel: string, lang: any }) => void;
}
const { Search } = Input;
const FilterTable: FC<FilterTableProps> = ({
  buttonText,
  isUpload = true,
  isAdded = true,
  isFilter,
  search_placeholder,
  isFilterLang,
  items,
  isFilterQuestionsBank,
  isSearch,
  onClick,
  onClickUpload,
  onSearch,
  onRadioChange,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [questionType, setQuestionType] = useState<string | null>(null);
  const [difficultyLevel, setDifficultyLevel] = useState<string | null>(null);
  const [lang, setLan] = useState<any | null>(null);

  const handleQuestionTypeChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setQuestionType(value);
    if (onRadioChange) {
      onRadioChange({ questionType: value, difficultyLevel: difficultyLevel || "", lang: lang || "" });
    }
  };

  const handleDifficultyChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setDifficultyLevel(value);
    if (onRadioChange) {
      onRadioChange({ questionType: questionType || "", difficultyLevel: value, lang: lang || "" });
    }
  };

  const handleLanguageChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setLan(value);
    if (onRadioChange) {
      onRadioChange({ questionType: questionType || "", difficultyLevel: difficultyLevel || "", lang: value || "" });
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const clearFilter = () => {
    setQuestionType(null);
    setDifficultyLevel(null);
    setLan(null);
    if (onRadioChange) {
      onRadioChange({ questionType: "", difficultyLevel: "", lang: "" });
    }
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"} className='my-3'>
      <Col className='text-[16px] text-normal mb-3' style={{ color: "rgba(0, 0, 0, 0.85)" }}>
        <Breadcrumb
          className='text-[16px] text-normal'
          separator=">"
          items={items}
        />
      </Col>
      <Col className='mb-3'>
        <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"}>
          {isSearch ?? (
            <Col className='mb-3'>
              <Search
                placeholder={intl.formatMessage({
                  id: search_placeholder || 'search',
                })}
                onSearch={onSearch}
                className="xl:w-[350px]"
              />
            </Col>
          )}
          {isFilter ?? (
            <Popover className='mb-3'
              content={
                <>
                  {isFilterLang ?? (
                    <>
                      <Flex justify='space-between'>
                        <h4 className='mt-0'><FormattedMessage id='language' /></h4>
                        <ClearOutlined onClick={clearFilter} />
                      </Flex>
                      <Radio.Group onChange={handleLanguageChange} value={lang}>
                        <Radio value={1}><FormattedMessage id='arabic' /></Radio>
                        <Radio value={2}><FormattedMessage id='english' /></Radio>
                      </Radio.Group>
                    </>
                  )}
                  {isFilterQuestionsBank ?? (
                    <div>
                      <div>
                        <Flex justify='space-between'>
                          <h4><FormattedMessage id='question_type' /></h4>
                          {isFilterLang && <ClearOutlined onClick={clearFilter} />}
                        </Flex>
                        <Radio.Group onChange={handleQuestionTypeChange} value={questionType}>
                          <Radio value={"mcq"}><FormattedMessage id='mcq' /></Radio>
                          <Radio value={"true_false"}><FormattedMessage id='true_false' /></Radio>
                        </Radio.Group>
                      </div>
                      <div className='my-3'>
                        <h4><FormattedMessage id='difficulty_level' /></h4>
                        <Radio.Group onChange={handleDifficultyChange} value={difficultyLevel}>
                          <Radio value={"easy"}><FormattedMessage id='easy' /></Radio>
                          <Radio value={"intermediate"}><FormattedMessage id='intermediate' /></Radio>
                          <Radio value={"hard"}><FormattedMessage id='hard' /></Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  )}
                </>
              }
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button >
                <svg width="20" height="23" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.2008 6.5999H2.40078C2.24165 6.5999 2.08904 6.53669 1.97652 6.42417C1.864 6.31164 1.80078 6.15903 1.80078 5.9999C1.80078 5.84077 1.864 5.68816 1.97652 5.57564C2.08904 5.46312 2.24165 5.3999 2.40078 5.3999H10.2008C10.3599 5.3999 10.5125 5.46312 10.625 5.57564C10.7376 5.68816 10.8008 5.84077 10.8008 5.9999C10.8008 6.15903 10.7376 6.31164 10.625 6.42417C10.5125 6.53669 10.3599 6.5999 10.2008 6.5999ZM15.6008 6.5999H13.2008C13.0417 6.5999 12.889 6.53669 12.7765 6.42417C12.664 6.31164 12.6008 6.15903 12.6008 5.9999C12.6008 5.84077 12.664 5.68816 12.7765 5.57564C12.889 5.46312 13.0417 5.3999 13.2008 5.3999H15.6008C15.7599 5.3999 15.9125 5.46312 16.025 5.57564C16.1376 5.68816 16.2008 5.84077 16.2008 5.9999C16.2008 6.15903 16.1376 6.31164 16.025 6.42417C15.9125 6.53669 15.7599 6.5999 15.6008 6.5999Z" fill="#9999A7"></path>
                  <path d="M11.7008 8.0999C11.2854 8.0999 10.8794 7.97674 10.5341 7.74599C10.1887 7.51524 9.91958 7.18726 9.76063 6.80354C9.60169 6.41981 9.5601 5.99757 9.64113 5.59021C9.72216 5.18285 9.92217 4.80867 10.2159 4.51498C10.5095 4.22129 10.8837 4.02128 11.2911 3.94025C11.6985 3.85923 12.1207 3.90081 12.5044 4.05976C12.8881 4.2187 13.2161 4.48786 13.4469 4.83321C13.6776 5.17855 13.8008 5.58456 13.8008 5.9999C13.8008 6.55686 13.5795 7.091 13.1857 7.48483C12.7919 7.87865 12.2577 8.0999 11.7008 8.0999ZM11.7008 5.0999C11.5228 5.0999 11.3488 5.15269 11.2008 5.25158C11.0528 5.35047 10.9374 5.49103 10.8693 5.65549C10.8012 5.81994 10.7833 6.0009 10.8181 6.17548C10.8528 6.35007 10.9385 6.51043 11.0644 6.6363C11.1903 6.76217 11.3506 6.84788 11.5252 6.88261C11.6998 6.91734 11.8807 6.89951 12.0452 6.83139C12.2097 6.76328 12.3502 6.64792 12.4491 6.49992C12.548 6.35191 12.6008 6.17791 12.6008 5.9999C12.6008 5.76121 12.506 5.53229 12.3372 5.36351C12.1684 5.19472 11.9395 5.0999 11.7008 5.0999ZM15.6008 12.5999H7.80078C7.64165 12.5999 7.48904 12.5367 7.37652 12.4242C7.264 12.3116 7.20078 12.159 7.20078 11.9999C7.20078 11.8408 7.264 11.6882 7.37652 11.5756C7.48904 11.4631 7.64165 11.3999 7.80078 11.3999H15.6008C15.7599 11.3999 15.9125 11.4631 16.025 11.5756C16.1376 11.6882 16.2008 11.8408 16.2008 11.9999C16.2008 12.159 16.1376 12.3116 16.025 12.4242C15.9125 12.5367 15.7599 12.5999 15.6008 12.5999ZM4.80078 12.5999H2.40078C2.24165 12.5999 2.08904 12.5367 1.97652 12.4242C1.864 12.3116 1.80078 12.159 1.80078 11.9999C1.80078 11.8408 1.864 11.6882 1.97652 11.5756C2.08904 11.4631 2.24165 11.3999 2.40078 11.3999H4.80078C4.95991 11.3999 5.11252 11.4631 5.22505 11.5756C5.33757 11.6882 5.40078 11.8408 5.40078 11.9999C5.40078 12.159 5.33757 12.3116 5.22505 12.4242C5.11252 12.5367 4.95991 12.5999 4.80078 12.5999Z" fill="#9999A7"></path>
                  <path d="M6.29922 14.0999C5.88388 14.0999 5.47787 13.9767 5.13252 13.746C4.78718 13.5152 4.51802 13.1873 4.35907 12.8035C4.20013 12.4198 4.15854 11.9976 4.23957 11.5902C4.3206 11.1829 4.52061 10.8087 4.8143 10.515C5.10799 10.2213 5.48217 10.0213 5.88953 9.94025C6.29689 9.85923 6.71913 9.90081 7.10285 10.0598C7.48658 10.2187 7.81455 10.4879 8.04531 10.8332C8.27606 11.1785 8.39922 11.5846 8.39922 11.9999C8.39922 12.5569 8.17797 13.091 7.78414 13.4848C7.39032 13.8787 6.85617 14.0999 6.29922 14.0999ZM6.29922 11.0999C6.12122 11.0999 5.94721 11.1527 5.79921 11.2516C5.6512 11.3505 5.53585 11.491 5.46773 11.6555C5.39961 11.8199 5.38179 12.0009 5.41651 12.1755C5.45124 12.3501 5.53696 12.5104 5.66282 12.6363C5.78869 12.7622 5.94906 12.8479 6.12364 12.8826C6.29822 12.9173 6.47918 12.8995 6.64363 12.8314C6.80809 12.7633 6.94865 12.6479 7.04754 12.4999C7.14644 12.3519 7.19922 12.1779 7.19922 11.9999C7.19922 11.7612 7.1044 11.5323 6.93562 11.3635C6.76683 11.1947 6.53791 11.0999 6.29922 11.0999Z" fill="#9999A7"></path>
                </svg>
              </Button>
            </Popover>
          )}
          {isAdded && (
            <Col className='mb-3'>
              <Button type="text" onClick={onClick} className='text-white bg-[#0E103A] text-[10px] md:text-[14px] lg:text-[14px] xl:text-[14px] xxl:text-[14px]' icon={<PlusOutlined />}>
                <Space>
                  {buttonText}
                </Space>
              </Button>
            </Col>
          )}
          {isUpload && (
            <Col className='mb-3'>
              <Button type="default" onClick={onClickUpload} className='text-black' icon={<UploadOutlined />}>
                <Space>
                  <FormattedMessage id='upload' />
                </Space>
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default FilterTable;