import { useState } from 'react';
import { Button, Col, Flex, Form, Modal, Row, Select, message, Progress } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import queryClient from '../../../services/react-query/queryClient';
import { InboxOutlined } from "@ant-design/icons";
import Dragger from 'antd/es/upload/Dragger';
import { FormattedMessage } from 'react-intl';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import axios from 'axios';

interface AddModalProps {
  visible: boolean;
  onClose: () => void;
  eLearningData: any;
}

const AddChapter: React.FC<AddModalProps> = ({
  visible,
  onClose,
  eLearningData,
}) => {
  const [fileValue, setFileValue] = useState<any>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [form] = Form.useForm();

  const { mutate: onAddChapter, isLoading } = useMutation(async (form_data: FormData) => {
    await axios.post('https://ibdl-api.techiesonic.com/api/IBDL', form_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      },
    });
  }, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.eLearningChapters]);
      onCancel();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const values = {
    mode: "chapter",
    type: "get",
  };

  const { data: listChapters } = useQuery(
    [queryKeys.listChapters],
    () => FetchData({
      ...values,
      certificate_id: eLearningData?.certificate_id,
      level_id: eLearningData?.level_id,
    }),
    {
      keepPreviousData: true,
    }
  );

  const handleFileChange = (info: any) => {
    setFileValue(info.file.originFileObj);
  };

  const onCancel = () => {
    form.resetFields();
    setUploadProgress(0);
    setFileValue(0)
    onClose();
  };

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    console.log(fileValue);
    form_data.append('mode', "chapter");
    form_data.append('type', "assign_chapter");
    form_data.append('chapter_id', values.chapter_id);
    form_data.append('e_learning_id', eLearningData?.id);
    form_data.append('content', fileValue);

    onAddChapter(form_data);
  };

  const decodedResponse = decodeResponse(listChapters);

  const { data: eLearningChapters } = useQuery(
    [queryKeys.eLearningChapters],
    () => FetchData({
      mode: "chapter",
      type: "elearning_chapters",
      e_learning_id: eLearningData.id
    }),
    { keepPreviousData: true }
  );

  const decodedELearningChaptersResponse = decodeResponse(eLearningChapters);
  const decryptedELearningChaptersData = decodedELearningChaptersResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['name', 'media', 'index_path', 'e_learning', 'chapter'];

    return Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => [
        key,
        excludedFields.includes(key) ? item[key] : value,
      ])
    );
  }) || [];

  const existingChapterIds = decryptedELearningChaptersData?.map((item: { chapter_id: any }) => item.chapter_id) || [];
  const availableChapters = decodedResponse?.data?.filter((item: { id: any }) => !existingChapterIds.includes(item.id)) || [];

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='add_chapter' />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              label={<FormattedMessage id={'chapter'} />}
              required={false}
              name="chapter_id"
              rules={[{ required: true, message: 'Missing Chapter' }]}
            >
              <Select
                options={availableChapters.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Dragger
            onChange={handleFileChange}
            multiple={false}
            showUploadList={false}
          >
            <div className="p-5">
              {fileValue ? (
                <div className="flex items-center justify-center gap-[10px] py-2 px-6 border-[1px] border-solid w-fit mx-auto rounded-lg border-[#75757E]">
                  <InboxOutlined style={{ fontSize: "19px", color: "#9999A7" }} />
                  <span className="text-[#75757E]">{fileValue?.name}</span>
                </div>
              ) : (
                <>
                  <div className="flex items-center justify-center gap-[10px] py-2 px-6 border-[1px] border-solid w-fit mx-auto rounded-lg border-[#75757E]">
                    <InboxOutlined style={{ fontSize: "19px", color: "#9999A7" }} />
                    <span className="text-[#75757E]"><FormattedMessage id={'upload_text1'} /></span>
                  </div>
                  <span className="text-xs font-normal text-[#75757E]">
                    <FormattedMessage id={'upload_text2'} />
                  </span>
                </>
              )}
            </div>
          </Dragger>
        </Form.Item>

        {uploadProgress > 0 && (
          <Progress
            className='mb-5'
            percent={uploadProgress}
            percentPosition={{ align: 'center', type: 'inner' }}
            size={[975, 20]}
          />
        )}

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default AddChapter;