enum questionDifficultyEnumM {
  Easy = "easy",
  Intermediate = "intermediate",
  Hard = "hard",
}

const difficultyLabelsEn: { value: string; label: string }[] = [
  { value: questionDifficultyEnumM.Easy, label: 'Easy' },
  { value: questionDifficultyEnumM.Intermediate, label: 'Intermediate' },
  { value: questionDifficultyEnumM.Hard, label: 'Hard' },
];

const difficultyLabelsAr: { value: string; label: string }[] = [
  { value: questionDifficultyEnumM.Easy, label: 'سهل' },
  { value: questionDifficultyEnumM.Intermediate, label: 'متوسط' },
  { value: questionDifficultyEnumM.Hard, label: 'صعب' },
];

export { questionDifficultyEnumM, difficultyLabelsEn, difficultyLabelsAr };