import React, { useState } from 'react';
import { Col, Input } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { deleteData } from '../../../network/deleteData';
import DeleteModal from '../../common/DeleteModal';
import UpdateModal from '../../common/UpdateModal';
import { StoreData } from '../../../network/storeDate';
import AddModal from '../../common/AddModal';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import FilterTable from '../../common/filtersTables/FilterTable';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import PaginationComponent from '../../common/PaginationComponent';

interface InfoProps {
  assessmentData: any;
}

const Company: React.FC<InfoProps> = ({ assessmentData }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (id: React.Key, action: 'add' | 'update' | "delete" | "upload") => {
    setSelectedRowId(id);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const values = {
    mode: "assessment_company",
    assessment_type_id: assessmentData.id,
    type: "get"
  };

  const deleteValue = {
    mode: "assessment_company",
    type: "delete",
    id: selectedRowId
  };

  const { data: listCompanies, isLoading, isFetching } = useQuery(
    [queryKeys.listCompanies, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listCompanies);
  const decryptedData = decodedResponse?.data?.map(decryptItem) ?? [];

  const formFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Company Name is required" }], input: <Input />, colSize: 24 },
  ];

  const updateFormFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Company Name is required" }], input: <Input />, colSize: 24 },
  ];

  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='companies' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_company' />}
        items={items}
        onClick={() => showModal(1, 'add')}
        onClickUpload={() => showModal(1, 'upload')}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
      />

      <AddModal
        visible={isModalVisible && modalAction === "add"}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'company'} />}
          </span>
        }
        queryKey={queryKeys.listCompanies}
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={null}
        formFields={formFields}
        mode='assessment_company'
        assessmentData={assessmentData.id}
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'company'} />}
          </span>
        }
        queryKey={queryKeys.listCompanies}
        onClose={closeModal}
        selectedRowId={selectedRowId}
        onSubmit={StoreData}
        initialValues={{
          name: decryptedData.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.name,
        }}
        formFields={updateFormFields}
        mode='assessment_company'
      />


      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'company'} />}
        queryKey={queryKeys.listCompanies}
        onClose={closeModal}
      />

      <PaginationComponent
        gutterNumber={16}
        spanNumber={6}
        isLoading={isLoading}
        decryptedData={decryptedData}
        isFetching={isFetching}
        actions={(item: { id: React.Key; }) => (
          <>
            <Col><EditOutlined key="edit" onClick={() => showModal(item.id, 'update')} /></Col>
            <Col><DeleteOutlined key="delete" className='text-red-400' onClick={() => showModal(item.id, 'delete')} /></Col>
          </>
        )}
      />
    </>
  );
};

export default Company;
