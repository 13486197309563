import React, { useState } from 'react';
import { Empty, Flex, Pagination, Space, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptData, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import AddExamScheduling from './AddExamScheduling';
import EditExamScheduling from './EditExamScheduling';

const ExamScheduling: React.FC = () => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | 'examData' | 'upload' | null>(null);
  const [selectedExamData, setSelectedExamData] = useState<{ data: any }[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'examData' | "upload", id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const values = {
    mode: "exam_schedule",
    type: "get",
  };

  const deleteValue = {
    mode: "exam_schedule",
    type: "delete",
    id: selectedRowId
  };

  const { data: listExamScheduling, isLoading, isFetching } = useQuery(
    [queryKeys.listExamScheduling, values, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listExamScheduling);
  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['status', 'scheduled_at', 'exam_configuration'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  const columns: ColumnsType<any> = [
    {
      title: <FormattedMessage id={'scheduled_at'} />,
      dataIndex: 'scheduled_at',
      width: 200,
    },
    {
      title: <FormattedMessage id={'status'} />,
      dataIndex: 'status',
      width: 200,
    },
    {
      title: <FormattedMessage id={'exam_configuration'} />,
      render: (text, record) => {
        const decryptedTitle = decryptData(record?.exam_configuration?.exam_title);
        return <span>{decryptedTitle}</span>;
      },
    },
    {
      key: 'operation',
      width: 100,
      render: (record: any) => (
        <Space>
          <Link to="/exam/schedule/data" className='text-gray-400' state={{ exam_configuration: record }}><EyeOutlined /></Link>
          <EditOutlined className='text-gray-400' onClick={() => {
            setSelectedExamData(record);
            showModal('update', record.id);
          }} />
          <DeleteOutlined style={{ color: "red" }} onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];

  const renderExamScheduling = () => {
    if (isLoading || isFetching) {
      return (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      );
    }

    if (!decryptedData || decryptedData?.length === 0) {
      return <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />;
    }

    return (
      <>
        {currentItems.map((item) => (
          <div key={item.id} className='mb-5 custom-table'>
            <Table
              bordered
              dataSource={[item]}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          </div>
        ))}
        <Flex justify='end'>
          <Pagination
            current={currentPage}
            total={decryptedData?.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </Flex>
      </>
    );
  };

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_exam_scheduling' />}
        tabName={<FormattedMessage id='exam_scheduling' />}
        onClick={() => showModal('add')}
        items={[{ title: <FormattedMessage id='exams' /> }]}
        isUpload={false}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <AddExamScheduling
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />

      <EditExamScheduling
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        examData={selectedExamData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'exam'} />}
        queryKey={queryKeys.listExamScheduling}
        onClose={closeModal}
      />

      {renderExamScheduling()}
    </>
  );
};

export default ExamScheduling;