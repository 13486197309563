import { useState } from 'react';
import { Button, Col, DatePicker, Flex, Form, Input, Modal, Row, Select, Upload, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { addFormData } from '../../../network/storeDate';
import { queryKeys } from '../../../services/react-query/queryKeys';
import queryClient from '../../../services/react-query/queryClient';
import { FormattedMessage } from 'react-intl';
import { gender } from '../../../enum/gender';
import { UploadOutlined } from '@ant-design/icons';

interface AddModalProps {
  visible: boolean;
  onClose: () => void;
  accreditationCenterData: any;
}
const AddAccreditationTrainer: React.FC<AddModalProps> = ({
  visible,
  onClose,
  accreditationCenterData,
}) => {
  const [form] = Form.useForm();
  const [fileState, setFileState] = useState<{
    cv?: { file: File; name: string };
  }>({});

  const { mutate: onAddAccreditationTrainer, isLoading } = useMutation(addFormData, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listAccreditationTrainer]);
      form.resetFields();
      onCancel();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const handleFileUpload = (type: keyof typeof fileState) => (info: { file: any; fileList: any }) => {
    const latestFile = info.fileList.slice(-1)[0]?.originFileObj;
    if (latestFile) {
      setFileState((prevState) => ({
        ...prevState,
        [type]: latestFile,
      }));
    }
  };

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "accreditation_trainer");
    form_data.append('type', "store");
    form_data.append('accreditation_center_id', accreditationCenterData.id);
    form_data.append('name', values.name);
    form_data.append('email', values.email);
    form_data.append('phone', values.phone);
    form_data.append('gender', values.gender);
    form_data.append('birth_date', values.birth_date);
    form_data.append('type_accreditation_trainer', values.type_accreditation_trainer);
    form_data.append('title', values.title);
    form_data.append('training_field', values.training_field);
    form_data.append('brief', values.brief);
    form_data.append('training_hours', values.training_hours);
    form_data.append('company', values.company);
    form_data.append('country', values.country);
    form_data.append('city', values.city);

    if (fileState.cv) {
      form_data.append('cv', fileState?.cv?.file);
    }

    onAddAccreditationTrainer(form_data);
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id={'add_accreditation_trainer'} />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        className="form-style mt-8 px-9 pb-5"
      >
        <Row className='w-[160px] bg-[#FAFAFA] mb-4 py-2 pl-2'>
          <Col >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
              <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
            </svg>
          </Col>
          <Col className='px-3'>
            <FormattedMessage id={'basic_information'} />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={6}>
            <Form.Item
              name="name"
              label={<FormattedMessage id={'name'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={6}>
            <Form.Item
              name="email"
              label={<FormattedMessage id={'email'} />}
              required={false}
              className="form-style w-full"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={6}>
            <Form.Item
              name="phone"
              label={<FormattedMessage id={'phone'} />}
              className="form-style w-full"
              required={false}
            >
              <PhoneInputWithCountrySelect
                defaultCountry="EG"
                countrySelectProps={{ disabled: true }}
                name='phone_country_code'
                international
                onChange={() => { }}
              />
            </Form.Item>

          </Col>
          <Col xl={6}>
            <Form.Item
              name="gender"
              label={<FormattedMessage id={'gender'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Please select gender!' },
              ]}
            >
              <Select
                options={gender.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={8}>
            <Form.Item
              name="birth_date"
              label={<FormattedMessage id={'birth_date'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Birth Date is required' },
              ]}
            >
              <DatePicker placeholder="" />
            </Form.Item>
          </Col>

          <Col xl={8}>
            <Form.Item
              name="training_field"
              label={<FormattedMessage id={'training_field'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Training Field is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="training_hours"
              label={<FormattedMessage id={'training_hours'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Training Hours is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={8}>
            <Form.Item
              name="title"
              label={<FormattedMessage id={'title'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'title is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xl={8}>
            <Form.Item
              name="brief"
              label={<FormattedMessage id={'brief'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Brief is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="company"
              label={<FormattedMessage id={'company'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Company is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row className='w-[160px] bg-[#FAFAFA] mb-4 py-2 pl-2'>
          <Col >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
              <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
            </svg>
          </Col>
          <Col className='px-3'>
            <FormattedMessage id={'location'} />:
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={12}>
            <Form.Item
              name="country"
              label={<FormattedMessage id={'country'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'country is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              name="city"
              label={<FormattedMessage id={'city'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'City is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Col >
          <Form.Item
            label={<FormattedMessage id={'cv'} />}
            name="cv"
            required={false}
            className="form-style w-full"
            rules={[
              { required: true, message: 'cv is required' },
            ]}
          >
            <Upload multiple={false} showUploadList={false} onChange={handleFileUpload('cv')}>
              <Button icon={<UploadOutlined />}>
                {
                  fileState.cv ?
                    fileState.cv?.name
                    : <FormattedMessage id={'click_to_Upload'} />
                }
              </Button>
            </Upload>
          </Form.Item>
        </Col>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default AddAccreditationTrainer;

