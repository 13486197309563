import React, { useState } from 'react';
import { Input, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import { StoreData } from '../../../network/storeDate';
import AddModal from '../../common/AddModal';
import UpdateModal from '../../common/UpdateModal';
import CustomTable from '../../common/CustomTable';
import { useLocation } from 'react-router-dom';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';

interface DataType {
  id: React.Key;
  name: string;
  email: string;
  start_date: string;
  end_date: string;
  success_rate: string;
  progress: string;
}


export default function UserBatchTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const data = location?.state.batch_data;

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const values = {
    mode: "batch",
    type: "get_user_batch",
    batch_id: data.id
  };

  const deleteValue = {
    mode: "batch",
    type: "delete_user_batch",
    id: selectedRowId
  };

  const { data: listUserBatchs, isLoading, isFetching } = useQuery(
    [queryKeys.listUserBatchs, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listUserBatchs);
  const decryptedData = decodedResponse?.data?.map(decryptItem) ?? [];

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id={'email'} />,
      dataIndex: 'email',
    },
    {
      title: <FormattedMessage id={'phone'} />,
      dataIndex: 'phone',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <EditOutlined className='text-gray-400' onClick={() => showModal('update', record.id)} />
          <DeleteOutlined style={{ color: "red" }} onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];


  const formFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input />, colSize: 8 },
    { name: "email", label: <FormattedMessage id={'email'} />, rules: [{ required: true, message: "Email is required" }], input: <Input />, colSize: 8 },
    {
      name: "phone", label: <FormattedMessage id={'phone'} />, input:
        <PhoneInputWithCountrySelect
          defaultCountry="EG"
          countrySelectProps={{ disabled: true }}
          name='phone_country_code'
          international
          onChange={() => { }}
        />, colSize: 8
    },
    {
      name: "batch_id", label: "", input: <Input className='hidden' />
      , colSize: 0
    },
  ];

  const updateFormFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input />, colSize: 8 },
    { name: "email", label: <FormattedMessage id={'email'} />, rules: [{ required: true, message: "Email is required" }], input: <Input />, colSize: 8 },
    {
      name: "phone", label: <FormattedMessage id={'phone'} />, input:
        <PhoneInputWithCountrySelect
          defaultCountry="EG"
          countrySelectProps={{ disabled: true }}
          name='phone_country_code'
          international
          onChange={() => { }}
        />, colSize: 8
    }, {
      name: "batch_id", label: "", input: <Input className='hidden' />
      , colSize: 0
    },];

  const items = [
    {
      title: <FormattedMessage id='corporates' />,
      href: '/corporate',
    },
    {
      title: <FormattedMessage id='user_batch' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_user_batch' />}
        items={items}
        onClick={() => showModal('add')}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
      />

      <AddModal
        visible={isModalVisible && modalAction === 'add'}
        confirmationHeader={<FormattedMessage id={'add_user_batch'} />}
        queryKey={queryKeys.listUserBatchs}
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={{
          batch_id: data.id,
        }}
        formFields={formFields}
        mode='batch'
        type='store_user_batch'
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={<FormattedMessage id={'edit_user_batch'} />}
        queryKey={queryKeys.listUserBatchs}
        onClose={closeModal}
        selectedRowId={selectedRowId}
        onSubmit={StoreData}
        initialValues={{
          name: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.name,
          email: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.email,
          phone: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.phone || '',
          batch_id: data.id,
        }}
        formFields={updateFormFields}
        mode='batch'
        type='update_user_batch'
      />


      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'user_batch'} />}
        queryKey={queryKeys.listUserBatchs}
        onClose={closeModal}
      />

      <CustomTable scroll={{ x: 'max-content' }} isFetching={isFetching} columns={columns} data={decryptedData} isLoading={isLoading} />

    </>
  );
};

