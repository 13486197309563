import { useEffect } from "react";
import {
  Row,
  Col,
  Button,
} from "antd";
import logox from "../../assets/logo.png";
import icon from "../../assets/icon-white1.png";
import { FormattedMessage } from "react-intl";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

function Header({
  onPress,
  visible
}) {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <div className="px-9 bg-white mt-5">
        <img src={logox} alt="" className="w-[200px] h-[60px] items-center" />
      </div>
      <Row className="text-white header-section">
        <Col>
          <img src={icon} alt="" className="w-[30px] h-[30px] grid justify-items-center content-center mt-1 mx-2" />
        </Col>
        <Col className="text-4xl">
          <FormattedMessage id={'IBDL_admin_control'} />
        </Col>
      </Row>
      <Button
        type="link"
        className="sidebar-toggler hidden"
        onClick={() => onPress()}
      >
        {visible ? <CloseOutlined /> : <MenuOutlined />}
      </Button>
      <div></div>
    </>
  );
}

export default Header;
