import { css } from "@emotion/css";
import { Drawer, Layout } from "antd";
import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TopHeader from "./TopHeader";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { useSelector } from "react-redux";
import SidebarMenu from "./SidebarMenu";
const { Header: AntHeader, Content, Sider } = Layout;

const OwnLayoutStyles = css`
    display: flex;
    height: 75vh;
  `;
const contentStyles = css`
    flex: 1;
    overflow-y: auto;
    height: calc(75vh);
  `;

function OwnLayout() {
  const currentLang = useSelector((state) => state?.user?.currentLang);
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const openDrawer = () => setVisible(!visible);
  useEffect(() => {
    if (currentLang === "ar") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [currentLang]);

  return (
    <Layout className={OwnLayoutStyles}>
      <Layout>
        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          key={placement === "right" ? "left" : "right"}
          width={255}
          className={`drawer-sidebar ${currentLang === "ar" ? "drawer-sidebar-rtl" : ""} `}
        >
          <Layout
            className={`layout-dashboard ${currentLang === "ar" ? "layout-dashboard-rtl" : ""
              }`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary active-route}`}
            >
              <SidebarMenu />
            </Sider>
          </Layout>
        </Drawer>
      </Layout>
      <TopHeader />
      <AntHeader>
        <Header
          onPress={openDrawer}
          name={currentLang}
          visible={visible}
        />
      </AntHeader>
      <Layout>
        <Sidenav />
        <Layout className={contentStyles}>
          <Content className="m-[2.8vw]" >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default OwnLayout;
