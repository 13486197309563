import React, { useState } from 'react';
import { Card, Col, Input, Modal, Row, Select, Tooltip } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import UpdateModal from '../../common/UpdateModal';
import { StoreData } from '../../../network/storeDate';
import AddModal from '../../common/AddModal';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import { lang } from '../../../enum/lang';
import { DeleteOutlined, EditOutlined, EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import PaginationComponentDynmicData from '../../common/PaginationComponentDynmicData';

interface InfoProps {
  assessmentData: any;
}

const AdviceTable: React.FC<InfoProps> = ({ assessmentData }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | "show" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLanguageId, setSelectedLanguageId] = useState<number | null>(null);
  const [language, setLang] = useState<string>("");

  const handleRadioChange = ({ lang }: { questionType: string; difficultyLevel: string, lang: string }) => {
    setLang(lang);
  };

  const handleLanguageChange = (value: number) => {
    setSelectedLanguageId(value);
  };

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload' | "show", id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "assessment_advice",
    type: "delete",
    id: selectedRowId
  };

  const categoriesValues = {
    mode: "assessment_sub_category",
    type: "get",
    category: selectedLanguageId === 1 ? 1 : selectedLanguageId === 2 ? 2 : null
  };

  const values = {
    mode: "assessment_advice",
    type: "get",
    assessment_type_id: assessmentData.id,
    language_id: language
  };

  const { data: listAdvices, isLoading, isFetching } = useQuery(
    [queryKeys.listAdvice, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      advice: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listAdvices);
  const decryptedData = decodedResponse?.data.map(decryptItem);
  const { data: listCategories } = useQuery(
    [queryKeys.listCategories, categoriesValues],
    () => FetchData(categoriesValues)
  );

  const decodedCategoriesResponse = decodeResponse(listCategories);
  const decryptedCategoriesData = (decodedCategoriesResponse?.data || []).map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['category_code'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const formFields = [
    {
      name: "language_id", label: <FormattedMessage id='language' />,
      rules: [{
        required: true, message: 'language is required'
      }],
      input: <Select
        options={lang.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        onChange={handleLanguageChange}
      />
      , colSize: 8
    },
    {
      name: "range_value",
      label: (
        <span>
          <FormattedMessage id='range_value' />
          <Tooltip title="Enter a score range (e.g., 0-4)">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      rules: [
        { required: true, message: "Range value is required" },
        {
          pattern: /^\d+\s*-\s*\d+$/,
          message: 'Range value must be in the format number - number (e.g., 0-4)',
        }
      ],
      input: <Input />,
      colSize: 8
    },
    {
      name: "sub_assessment_category_id", label: <FormattedMessage id='category' />, rules: [{ required: true, message: "Assessment category is required" }], input: <Select
        placeholder="Enter category"
        options={decryptedCategoriesData?.map((item: any) => ({
          value: item?.id,
          label: item?.name
        }))}
      />, colSize: 8
    },
    {
      name: "advice", label: <FormattedMessage id='advice' />, rules: [{ required: true, message: "Advice is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 24
    },
    {
      name: "advice_if_high_boss", label: <FormattedMessage id='advice_boss_high' />, rules: [{ required: true, message: "Advice Boss (High) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
    {
      name: "advice_if_low_boss", label: <FormattedMessage id='advice_boss_low' />, rules: [{ required: true, message: "Advice Boss (Low) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
    {
      name: "advice_if_high_candidate", label: <FormattedMessage id='advice_candidate_high' />, rules: [{ required: true, message: "Advice Candidate (High) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
    {
      name: "advice_if_low_candidate", label: <FormattedMessage id='advice_candidate_low' />, rules: [{ required: true, message: "Advice Candidate (Low) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
  ];
  const updateFormFields = [
    {
      name: "language_id", label: <FormattedMessage id='language' />,
      rules: [{
        required: true, message: 'language is required'
      }],
      input: <Select
        options={lang.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        onChange={handleLanguageChange}
      />
      , colSize: 8
    },
    {
      name: "range_value",
      label: (
        <span>
          <FormattedMessage id='range_value' />
          <Tooltip title="Enter a score range (e.g., 0-4)">
            <QuestionCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </span>
      ),
      rules: [
        { required: true, message: "Range value is required" },
        {
          pattern: /^\d+\s*-\s*\d+$/,
          message: 'Range value must be in the format number - number (e.g., 0-4)',
        }
      ],
      input: <Input />,
      colSize: 8
    },
    {
      name: "sub_assessment_category_id", label: <FormattedMessage id='category' />, rules: [{ required: true, message: "Assessment category is required" }], input: <Select
        options={decryptedCategoriesData?.map((item: any) => ({
          value: item?.id,
          label: item?.name
        }))}
      />, colSize: 8
    },
    {
      name: "advice", label: <FormattedMessage id='advice' />, rules: [{ required: true, message: "Advice is required" }],
      input:
        <Input.TextArea
          id="outlined-basic"
          rows={3}
          size="large"

        />
      , colSize: 24
    },
    {
      name: "advice_if_high_boss", label: <FormattedMessage id='advice_boss_high' />, rules: [{ required: true, message: "Advice Boss (High) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
    {
      name: "advice_if_low_boss", label: <FormattedMessage id='advice_boss_low' />, rules: [{ required: true, message: "Advice Boss (Low) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
    {
      name: "advice_if_high_candidate", label: <FormattedMessage id='advice_candidate_high' />, rules: [{ required: true, message: "Advice Candidate (High) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
    {
      name: "advice_if_low_candidate", label: <FormattedMessage id='advice_candidate_low' />, rules: [{ required: true, message: "Advice Candidate (Low) is required" }], input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
      />, colSize: 12
    },
  ];

  const renderCardData = (item: any) => (
    <div>{item.advice}</div>
  );

  const truncateText = (text: string, maxLength: number) =>
    text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;

  const renderCardTile = (item: any) => (
    <div>
      <Row>
        <Col className='text-[#0E103A]'>Range Value:  &nbsp;</Col>
        <Col>{truncateText(item?.range_value, 180)}</Col>
      </Row>
    </div>
  );

  const renderActions = (item: any) => (
    [
      <EditOutlined key="edit" onClick={() => showModal('update', item.id)} />,
      <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} />
    ]
  );
  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='advises' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_advises' />}
        items={items}
        onClick={() => showModal('add')}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
        onRadioChange={handleRadioChange}
        isFilterQuestionsBank={false}
      />

      <AddModal
        visible={isModalVisible && modalAction === "add"}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'advice'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={null}
        formFields={formFields}
        assessmentData={assessmentData.id}
        queryKey={queryKeys.listAdvice}
        mode='assessment_advice'
      />

      <UpdateModal
        visible={isModalVisible && modalAction === "update"}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'advice'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        selectedRowId={selectedRowId}
        initialValues={{
          range_value: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.range_value,
          advice: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.advice,
          sub_assessment_category_id: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.sub_assessment_category_id,
          advice_if_high_boss: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.advice_if_high_boss,
          advice_if_low_boss: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.advice_if_low_boss,
          advice_if_high_candidate: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.advice_if_high_candidate,
          advice_if_low_candidate: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.advice_if_low_candidate,
          language_id: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.language_id,
        }}
        formFields={updateFormFields}
        queryKey={queryKeys.listAdvice}
        mode='assessment_advice'
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'advice'} />}
        queryKey={queryKeys.listAdvice}
        onClose={closeModal}
      />

      <PaginationComponentDynmicData
        gutterNumber={12}
        spanNumber={6}
        decryptedData={decryptedData}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={renderActions}
        data={renderCardData}
        title={renderCardTile}
        extra={(item: any) => (<EyeOutlined key="view" onClick={() => { showModal('show', item.id); setSelectedData(item); }} />)}
      />

      <Modal
        open={isModalVisible && modalAction === 'show'}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={[]}
        onCancel={closeModal}
        width={850}
      >
        <Card className='m-4'>
          {selectedData && (
            <div>
              <Row>
                <Col span={24} className='bg-[#F5F5F5] p-2 mb-4'> <FormattedMessage id='advice' />:</Col>
              </Row>
              <Row className='mb-4'>
                <Col span={24} className='mb-4'><Card style={{ minHeight: "10vh", border: "1px solid #000" }}>{selectedData.advice}</Card></Col>
              </Row>
              <Row gutter={12}>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'> <FormattedMessage id='advice_boss_high' />:</Col>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'><FormattedMessage id={'advice_candidate_high'} />:</Col>
              </Row>
              <Row className='mb-4' gutter={12}>
                <Col span={12} className='mb-4'><Card style={{ minHeight: "10vh", border: "1px solid #000" }}>{selectedData.advice_if_high_boss}</Card></Col>
                <Col span={12} className='mb-4'><Card style={{ minHeight: "10vh", border: "1px solid #000" }}>{selectedData.advice_if_high_candidate}</Card></Col>
              </Row>
              <Row gutter={12}>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'> <FormattedMessage id='advice_boss_low' />:</Col>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'><FormattedMessage id={'advice_candidate_low'} />:</Col>
              </Row>
              <Row className='mb-4' gutter={12}>
                <Col span={12} className='mb-4'><Card style={{ minHeight: "10vh", border: "1px solid #000" }}>{selectedData.advice_if_low_boss}</Card></Col>
                <Col span={12} className='mb-4'><Card style={{ minHeight: "10vh", border: "1px solid #000" }}>{selectedData.advice_if_low_candidate}</Card></Col>
              </Row>
            </div>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default AdviceTable;