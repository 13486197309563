import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Flex, Form, Modal, Row, Select, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { addELearning } from '../../network/storeDate';
import queryClient from '../../services/react-query/queryClient';
import TextArea from 'antd/es/input/TextArea';
import { FormattedMessage } from 'react-intl';
import { FetchData } from '../../network/fetchData';
import { decodeResponse, decryptItem } from '../../helpers/apiUtils';

interface AddModalProps {
  visible: boolean;
  onClose: () => void;
  eLearningData: any;
}

const UpdateELearning: React.FC<AddModalProps> = ({
  visible,
  onClose,
  eLearningData,
}) => {
  const [form] = Form.useForm();
  const [selectedCertificate, setSelectedCertificate] = useState<string | undefined>(eLearningData?.certificate_id);

  const { mutate: onUpdateELearning, isLoading } = useMutation(addELearning, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listELearnings]);
      onClose();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const values = {
    mode: "certificate",
    type: "get"
  };

  const { data: listCertificates } = useQuery(
    [queryKeys.listCertificates, values],
    () => FetchData({
      ...values,
    }),
    {
      keepPreviousData: true,
    }
  );

  const { data: listLevels } = useQuery(
    [queryKeys.listLevels, selectedCertificate],
    () => FetchData({
      mode: 'level',
      type: 'get',
      certificate_id: selectedCertificate
    }),
    {
      enabled: !!selectedCertificate,
      keepPreviousData: true,
    }
  );

  const decodedLevelsResponse = decodeResponse(listLevels);
  const decodedResponse = decodeResponse(listCertificates);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const eLearningValues = {
    mode: "e-learning",
    type: "get",
  };

  const { data: listELearnings } = useQuery(
    [queryKeys.listELearnings, eLearningValues],
    () => FetchData({
      ...eLearningValues,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedtELearningResponse = decodeResponse(listELearnings);
  const decryptedtELearningData = decodedtELearningResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['certificate', 'level_id'];

    return Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => [
        key,
        excludedFields.includes(key) ? item[key] : value,
      ])
    );
  }) || [];


  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "e-learning");
    form_data.append('type', "update");
    form_data.append('certificate_id', values?.certificate_id);
    form_data.append('level_id', values?.level_id);
    form_data.append('id', eLearningData?.id);
    form_data.append('instruction', encodeURIComponent(values?.instruction));

    onUpdateELearning(form_data);
  };

  const initialValues = useMemo(() => ({
    certificate_id: eLearningData?.certificate_id,
    level_id: eLearningData?.level_id,
    instruction: decodeURIComponent(eLearningData?.instruction),
  }), [eLearningData]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }

  }, [visible, selectedCertificate, initialValues, form]);

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='edit_e_learning' />}
      width={1050}
    >
      <Form
        onFinish={onFinish}
        name="complex-form"
        form={form}
        layout="vertical"
        className="form-style mt-8 px-9 pb-5"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label={<FormattedMessage id={'certificate'} />}
              required={false}
              name="certificate_id"
              rules={[{ required: true, message: 'Missing Certificate' }]}
            >
              <Select
                options={decodedResponse?.data?.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                onChange={(value) => setSelectedCertificate(value)}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label={<FormattedMessage id={'level'} />}
              required={false}
              name="level_id"
              rules={[{ required: true, message: 'Missing Level' }]}
            >
              <Select
                options={decodedLevelsResponse?.data
                  ?.filter((level: { id: any }) =>
                    !decryptedtELearningData.some((eLearning: { level_id: any }) =>
                      eLearning.level_id === level.id
                    )
                  )
                  ?.map((item: { id: any; name: any }) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                onChange={(value) => setSelectedCertificate(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              name="instruction"
              label={<FormattedMessage id='instruction' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Instruction is required' },
              ]}
            >
              <TextArea rows={5}
                showCount />
            </Form.Item>
          </Col>
        </Row>


        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default UpdateELearning;

