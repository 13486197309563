import { Button, Col, Collapse, Flex, Form, Input, Modal, Row, Select, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { lang } from '../../../enum/lang';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { StoreData } from '../../../network/storeDate';
import queryClient from '../../../services/react-query/queryClient';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptData, decryptItem } from '../../../helpers/apiUtils';
import { Key, useEffect, useMemo } from 'react';

interface AddModalProps {
  visible: boolean;
  onClose: () => void;
  assessmentData: any;
  positionData: any;
}

const UpdatePosition: React.FC<AddModalProps> = ({
  visible,
  onClose,
  assessmentData,
  positionData,
}) => {
  const [form] = Form.useForm();

  const { mutate: onAddPosition, isLoading } = useMutation(StoreData, {
    onSuccess: () => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listPostions]);
      onCancel();
    },
    onError: () => {
      message.error("حاول مرة اخرى");
    },
  });

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const companyValues = {
    mode: "assessment_company",
    type: "get",
    assessment_type_id: assessmentData.id,
  };

  const { data: listCompanies } = useQuery(
    [queryKeys.listCompanies],
    () => FetchData(companyValues)
  );
  const decodedCompaniesResponse = decodeResponse(listCompanies);
  const decryptedCompaniesData = decodedCompaniesResponse?.data?.map(decryptItem) ?? [];

  const categoriesvalues = {
    mode: "assessment_category",
    type: "get",
    assessment_type_id: assessmentData.id,
  };

  const { data: listCategories } = useQuery(
    [queryKeys.listCategories, categoriesvalues, assessmentData.id],
    () => FetchData(categoriesvalues)
  );

  const decodedCategoriesResponse = decodeResponse(listCategories);
  const decryptedCategoriesData = (decodedCategoriesResponse?.data || []).map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['assessment_sub_categories'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const onFinish = async (values: any) => {
    const positionsSubcategory = Object.entries(values)
      .filter(([key]) => key.startsWith('standard_'))

      .map(([key, value]) => {
        const subCategoryId = key.split('_')[1];
        return {
          sub_assessment_category_id: subCategoryId,
          standard: value,
        };
      });

    const formData = {
      mode: "assessment_position",
      type: "update",
      id: positionData.id,
      assessment_type_id: assessmentData.id,
      positions_subcategory: positionsSubcategory,
      name: values.name,
      assessment_company_id: values.assessment_company_id,
      language_id: values.language_id,
    };
    onAddPosition(formData);
  };

  const initialValues = useMemo(() => ({
    name: positionData?.name,
    language_id: positionData?.language_id,
    assessment_company_id: positionData?.assessment_company_id,
    ...positionData?.sub_assessment_categories?.reduce((acc: any, subCategory: any) => {
      acc[`standard_${subCategory.id}`] = subCategory.pivot.standard;
      return acc;
    }, {})
  }), [positionData]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }

  }, [visible, initialValues, form]);

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='update_position' />}
      width={1050}
    >
      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
        <Col xl={8}>
            <Form.Item
              name="language_id"
              label={<FormattedMessage id='language' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'language is required' },
              ]}
            >
              <Select
                placeholder="choces language"
                options={lang?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="name"
              label={<FormattedMessage id='name' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="assessment_company_id"
              label={<FormattedMessage id='company' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'company is required' },
              ]}
            >
              <Select placeholder="Enter Company" options={decryptedCompaniesData?.map((item: any) => ({
                value: item?.id,
                label: item?.name,
              }))} />
            </Form.Item>
          </Col>
        </Row>
        <div className='mb-8'>
          {decryptedCategoriesData.map((category: { assessment_sub_categories: any[]; id: Key; name: string }) => (
            <Collapse key={category.id} className="mt-4">
              <Collapse.Panel header={category.name} key={`category_${category.id}`}>
                {
                  category?.assessment_sub_categories.map((subCategory: { id: any; name: any; }) => (
                    <Form.Item
                      key={`subCategory_${subCategory.id}`}
                      name={`standard_${subCategory.id}`}
                      required={false}
                      label={<FormattedMessage id="Standard" values={{ name: subCategory.name }} />}
                      rules={[{ required: true, message: `Please enter ${subCategory.name} standard` }]}
                    >
                      <Input placeholder={`Enter ${decryptData(subCategory.name)} Standard`} />
                    </Form.Item>
                  ))
                }
              </Collapse.Panel>
            </Collapse>
          ))}
        </div>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default UpdatePosition;

