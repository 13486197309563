import React, { useState } from 'react';
import { Button, Card, Col, Flex, Form, Input, Row, Select, TimePicker, Typography, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { StoreData } from '../../../network/storeDate';
import queryClient from '../../../services/react-query/queryClient';
import { useNavigate } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse } from '../../../helpers/apiUtils';
import CustomHeader from '../../common/CustomHeader';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import DeleteSVG from '../../../assets/common/DeleteSVG';
import { PlusOutlined, VerticalLeftOutlined } from '@ant-design/icons';

function AddExamConfigration() {
  const navigate = useNavigate();
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [form] = Form.useForm();

  const { mutate: onAddExam, isLoading } = useMutation(StoreData, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listExamConfiguration]);
      navigate("/exams/configuration");
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const onFinish = async (values: { exam_duration: { format: (arg0: string) => any; }; randomize_categories: any[] }) => {
    const examDurationFormatted = values.exam_duration.format('HH:mm');
    const totalDifficulties = values.randomize_categories.reduce((acc, category) => {
      const easy = parseInt(category.difficulties.easy) || 0;
      const intermediate = parseInt(category.difficulties.intermediate) || 0;
      const hard = parseInt(category.difficulties.hard) || 0;
      return acc + easy + intermediate + hard;
    }, 0);

    const questionsNumber = form.getFieldValue('number_of_questions');
    if (totalDifficulties !== parseInt(questionsNumber)) {
      message.error(`The total number of questions from categories (${totalDifficulties}) must match the selected number of questions (${questionsNumber}).`);
      return;
    }

    const data = {
      mode: "exam_configuration",
      type: "store",
      ...values,
      exam_duration: examDurationFormatted,
    };

    onAddExam(data);
  };

  const valueCertificate = {
    mode: "certificate",
    type: "get",
  };

  const { data: listCertificates } = useQuery(
    [queryKeys.listCertificates, valueCertificate],
    () => FetchData(valueCertificate)
  );

  const valueChapter = {
    mode: "chapter",
    type: "get",
    level_id: selectedLevel,
  };

  const valueLevel = {
    mode: "level",
    type: "get",
    certificate_id: selectedCertificate,
  };

  const { data: listChapters } = useQuery(
    [queryKeys.listChapters, selectedLevel],
    () => FetchData(valueChapter),
    {
      enabled: !!selectedLevel,
    }
  );

  const { data: listLevels } = useQuery(
    [queryKeys.listLevels, selectedCertificate],
    () => FetchData(valueLevel),
    {
      enabled: !!selectedCertificate,
    }
  );

  const onClose = () => {
    navigate("/exams/configuration");
  };

  const decodedResponse = decodeResponse(listCertificates);
  const decodedResponseExamChapters = decodeResponse(listChapters);
  const decodedResponseLevels = decodeResponse(listLevels);

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'add_exam'} />} />

      <Form
        onFinish={onFinish}
        name="complex-form"
        form={form}
        layout="vertical"
        className="form-style mt-8 p-1"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={12}>
            <Form.Item
              name="exam_title"
              label={<FormattedMessage id={'name'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'name is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              name="exam_duration"
              label={<FormattedMessage id={'exam_duration'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Exam Duration is required' },
              ]}
            >
              <TimePicker placeholder="" format="HH:mm" defaultOpenValue={dayjs('00:00', 'HH:mm')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={12}>
            <Form.Item
              name="number_of_questions"
              label={<FormattedMessage id={'number_of_questions'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'number_of_questions is required' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              name="certificate_id"
              label={<FormattedMessage id={'certificate'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'certificate is required' },
              ]}
            >
              <Select
                options={decodedResponse?.data?.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                onChange={(value) => setSelectedCertificate(value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              name="exam_notes"
              label={<FormattedMessage id={'instruction'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'instructions is required' },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Row className='xl:w-[200px] bg-[#F0F0F0] mb-4'>
          <Col className='py-3 xl:px-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0203571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
              <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.2057 3.07589C17.8749 3.0067 17.8749 2.89286 17.2057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
            </svg>
          </Col>
          <Col className='py-3'>
            <FormattedMessage id={'categories_randomizations'} />
          </Col>
        </Row>

        <Form.List name="randomize_categories">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Card bordered={false} key={key}>
                  <Flex justify="end">
                    <Button
                      type="text"
                      htmlType="button"
                      onClick={() => remove(name)}
                    >
                      <DeleteSVG />
                    </Button>
                  </Flex>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        {...restField}
                        label={<FormattedMessage id={'level'} />}
                        required={false}
                        name={[name, 'level_id']}
                        rules={[{ required: true, message: 'Missing Level' }]}
                      >
                        <Select
                          options={decodedResponseLevels?.data?.map((item: { id: any; name: any; }) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                          onChange={(value) => setSelectedLevel(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>

                      <Form.Item
                        {...restField}
                        label={<FormattedMessage id={'chapter'} />}
                        required={false}
                        name={[name, 'chapter_id']}
                        rules={[{ required: true, message: 'Missing Chapter' }]}
                      >
                        <Select
                          options={decodedResponseExamChapters?.data?.map((item: { id: any; name: any; }) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={15}>
                    <Col xl={6}>
                      <Typography.Title level={5} className='mt-10' type="secondary"><VerticalLeftOutlined /> <FormattedMessage id={'question_difficulties'} /></Typography.Title>
                    </Col>
                    <Col xl={6}>
                      <Form.Item
                        {...restField}
                        label={<FormattedMessage id={'easy'} />}
                        required={false}
                        name={[name, 'difficulties', 'easy']}
                        rules={[
                          { required: true, message: 'Missing Easy' }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xl={6}>
                      <Form.Item
                        {...restField}
                        label={<FormattedMessage id={'intermediate'} />}
                        required={false}
                        name={[name, 'difficulties', 'intermediate']}
                        rules={[
                          { required: true, message: 'Missing Intermediate' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xl={6}>
                      <Form.Item
                        {...restField}
                        label={<FormattedMessage id={'hard'} />}
                        required={false}
                        name={[name, 'difficulties', 'hard']}
                        rules={[
                          { required: true, message: 'Missing Hard' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  {index === -1 && <hr />}
                </Card>
              ))}
              <Form.Item>
                <Button style={{ width: "200px" }} className='mt-3' onClick={() => add()} block icon={<PlusOutlined />}>
                  <FormattedMessage id={'add_category'} />
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
            onClick={onClose}
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </>
  );
}

export default AddExamConfigration;
