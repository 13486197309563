import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import {
  SafetyCertificateOutlined,
  TeamOutlined,
  FileDoneOutlined,
  FormOutlined,
  BookOutlined,
  UserOutlined
} from '@ant-design/icons';
import '../../styles/SidebarMenu.css';
import ELearningSVG from '../../assets/common/ELearningSVG';
import { FormattedMessage } from 'react-intl';

const { SubMenu } = Menu;

const SidebarMenu = () => {
  const navigate = useNavigate();
  const userRoles = useSelector((s) => s?.user?.data?.user?.roles.map(role => role.name));

  const menuItems = [
    { role: 'admin', key: 'certificate', icon: <SafetyCertificateOutlined />, label: <FormattedMessage id="certificate" /> },
    { role: 'elearning_admin', key: 'e-learning', icon: <ELearningSVG />, label: <FormattedMessage id="e_learnings" /> },
    {
      role: 'exam_admin', icon: <BookOutlined />, label: <FormattedMessage id="exams" />,
      children: [
        { key: 'questions/bank', label: <FormattedMessage id="questions-bank" /> },
        { key: 'exams/configuration', label: <FormattedMessage id="exam_configuration" /> },
        { key: 'exams/schedule', label: <FormattedMessage id="exam_schedule" /> },
        { key: 'exams/monitoring', label: <FormattedMessage id="exams_monitoring" /> },
      ]
    },
    { role: 'assessment_admin', key: 'assessment', icon: <FormOutlined />, label: <FormattedMessage id="assessment" /> },
    { role: 'corporate_admin', key: 'corporate', icon: <TeamOutlined />, label: <FormattedMessage id="corporate" /> },
    { role: 'accreditation_admin', key: 'accreditation', icon: <FileDoneOutlined />, label: <FormattedMessage id="accreditation" /> },
    { role: 'admin', key: 'users', icon: <UserOutlined />, label: <FormattedMessage id="users" /> },
  ];

  return (
    <Menu
      defaultSelectedKeys={['1']}
      onClick={({ key }) => navigate(`/${key}`)}
      mode="inline"
    >
      {menuItems.filter(item => userRoles.includes(item.role) || userRoles.includes('admin')).map(item => (
        item.children ? (
          <SubMenu
            key={item.key}
            icon={item.icon}
            title={item.label}
            popupClassName="submenu-bottom"
          >
            {item.children.map(subItem => (
              <Menu.Item key={subItem.key} onClick={() => navigate(`/${subItem.key}`)} style={{ ...item.style, ...{ color: 'black' } }}>
                {subItem.label}
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={item.key} icon={item.icon} style={{ ...item.style, ...{ height: '45px', display: 'flex', alignItems: 'center', color: 'black' } }}>
            {item.label}
          </Menu.Item>
        )
      ))}
    </Menu>
  );
};

export default SidebarMenu;
